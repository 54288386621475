<template>
  <div class="referralCode">
    <div class="go-back">
      <div @click="onClickLeft">
        <van-icon name="arrow-left" class="icon-left"/>
        <span>填写推荐码</span>
      </div>
    </div>
    <div class="content">
      <div class="item-card">
        <div class="card-label">填写推荐码（6位）：</div>
        <van-field style="padding:10px 0px;border-bottom:1px solid #D0D0D0" v-model="referralForm.recommend_code" maxlength="6" @input="onCodeInput" placeholder="请输入推荐码" />
        <div class="card-hint">请向机构负责人索要机构码</div>
      </div>
      <div class="item-button">
        <div class="button" v-if="codeEmpty" @click="onNextStep">下一步</div>
        <div class="button" style="background:#708EB8" v-else>下一步</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getBusinessIdByCode} from '@/api/referral.js'
export default {
  name: 'referralCode',

  data() {
    return {
      referralForm:{
        recommend_code:""
      },
      codeEmpty:false
    };
  },

  mounted() {
    
  },

  methods: {
    async onNextStep(){
      const {code,data} = await getBusinessIdByCode(this.referralForm)
      if(code == 200){
        this.$router.push({
          path:"/stages",
          query:{
            business_id:data.business_id
          }
        });

      }
    },
    onClickLeft(){
      this.$router.push("/");
      this.referralForm = {
        recommend_code:""
      }
    },
    onCodeInput(value){
      this.referralForm.recommend_code = value
      if(this.referralForm.recommend_code == '' || value.length !=6 ){
        this.codeEmpty = false
      } else {
        this.codeEmpty = true
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.referralCode{
  height: 100%;   
  width: 100%;
  background: #f5f5f5;
  .go-back{
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 30px;
    width: 100%;
    background: #fff;
    border-top: 1px solid #E7E7E7;
    border-bottom: 1px solid #E7E7E7;
    height: 100px;
  }
  .content{
    padding: 30px;
    .item-card{
      padding: 30px;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 0px #F5F5F5;
      border-radius: 20px;
      color: #333333;
      font-size: 30px;
      .card-hint{
        margin-top: 30px;
        font-size: 24px;
        color: #666666;
      }
    }
    .item-button{
      padding: 30px;
      .button{
        background: #1677FF;
        box-shadow: 0px 7px 13px 0px rgba(22,119,255,0.32);
        border-radius: 50px;
        color: #fff;
        padding: 25px 0px;
        font-size: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>